import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Print from '@/utils/print.js'
import '@/style/index.css'
Vue.use(Print) // 打印
Vue.config.productionTip = false
Vue.use(ElementUI)

//引入自定义指令，方便全局使用
import myDirective from '@/utils/myDirective.js';
Vue.use(myDirective);
// 1.设置v-throttle自定义指令
Vue.directive('throttle', {
  bind: (el, binding) => {
    let throttleTime = binding.value // 防抖时间
    if (!throttleTime) { // 用户若不设置防抖时间，则默认2s
      throttleTime = 2000
    }
    let cbFun
    el.addEventListener('click', event => {
      if (!cbFun) { // 第一次执行
        cbFun = setTimeout(() => {
          cbFun = null
        }, throttleTime)
      } else {
        event && event.stopImmediatePropagation()
      }
    }, true)
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
