<template>
  <div id="app">
    <PageHead class="headPage" v-if="$route.meta.head"/>
    <router-view/>
    <el-dialog
      title="提示"
      :visible.sync="showIETip"
      width="30%"
      top="30vh"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      center
    >
      请使用谷歌、火狐、edge，或其他三方浏览器（360、搜狗等浏览器）高速模式访问本系统！
    </el-dialog>
  </div>
</template>

<script>
import PageHead from '@/components/PageHead'
import { getUserAgent } from '@/utils/util'
import webConfig from '@/utils/webSite/webSite.js'

export default {
  name: 'App',
  components: {
    PageHead
  },
  data () {
    return {
      showIETip: false
    }
  },
  watch:{
    '$route':function(newRoute){
      // 排除查看通知书
      if(newRoute.path!='/AppSeeNotifi'){
        const userAgent = window.navigator.userAgent
        if (/iPad|iPhone|midp|rv:1.2.3.4|ucweb|Android|windows ce|windows mobile/.test(userAgent)) {
          window.location.href =  webConfig.mobileUrl
        }
        const isIe = getUserAgent()
        if (isIe) {
          this.showIETip = true
        }
      }
    }
  },
  created () {}
}
</script>

<style lang="less">
html,
body {
  height: 100%;
}

#app {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.headPage {
  height: 100px;
}

body {
  padding: 0;
  margin: 0;
}

::v-deep .el-table th.el-table__cell {
  background-color: #f0f8fb !important;
}</style>
