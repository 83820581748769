<template>
  <div
    ref="login_page"
    class="loginPage"
    :style="{
    '--show_scale': show_scale,
    '--login_page_height': login_page_height + 'px',
    '--login_content_height': login_content_height + 'px'
     }"
  >
    <div ref="login_content" class="login_content">
      <div class="cont">
        <div class="lc_left">
<!--          <div class="title">-->
<!--            <div class="mes">-->
<!--              <div class="mes_l">通知与公告</div>-->
<!--              <div class="mes_r">MORE</div>-->
<!--            </div>-->
<!--          </div>-->
          <!-- <div class="school-general">
              <h2>招生简章</h2>
              <p>高等教育自学考试助学招生简章</p>
              <el-button type="primary" size="mini" round @click="lookDetails">查看详情</el-button> -->
          <!-- </div> -->
<!--          <div class="notice">-->
<!--            <div class="notice_item">-->
<!--              <div class="ni_l"><span>西华师范大学关于开展2022年下半年自学考试课程免试工作准备中需要注意的事项</span></div>-->
<!--              <div class="ni_r">2019-04-23</div>-->
<!--            </div>-->
<!--            <div class="notice_item">-->
<!--              <div class="ni_l"><span>关于四川省教育考试院停考英语（专科）等专业的通知</span></div>-->
<!--              <div class="ni_r">2019-04-23</div>-->
<!--            </div>-->
<!--            <div class="notice_item">-->
<!--              <div class="ni_l"><span>222次西华师范大学高等教育自学考试英语专业口语、听力等考试院关于受理2022年下半年的情况</span></div>-->
<!--              <div class="ni_r">2019-04-23</div>-->
<!--            </div>-->
<!--            <div class="notice_item">-->
<!--              <div class="ni_l"><span>四川省教育考试院关于受理2022年下半年高等教育自学考试，高等教育自学考试英语专业口语专业</span></div>-->
<!--              <div class="ni_r">2019-04-23</div>-->
<!--            </div>-->
<!--          </div>-->
          <div class="sch_introduce">
            <div class="si_one">
              <div class="si_one_l">
                <img src="../assets/login/1.jpg" alt="">
              </div>
              <div class="si_one_r">
                <div class="si_one_r_t">学校介绍</div>
                <div class="si_one_r_d">西南石油大学简介</div>
                <div style="margin-top: 15px;"><a href="https://www.swpu.edu.cn/xqzl/xxgk.htm" target="_blank"
                                                  rel="noopener noreferrer">
                  <el-button type="danger" size="mini" round
                  >查看详情
                  </el-button>
                </a></div>
              </div>
            </div>
            <div class="si_two">
              <div class="si_two_l">
                <img src="../assets/login/2.jpg" alt="">
              </div>
              <div class="si_two_r">
                <div class="si_two_r_t">招生简章</div>
                <div class="si_two_r_d">高等教育自学考试助学招生简章</div>
                <div style="margin-top: 15px;">
                  <el-button type="primary" size="mini" round v-throttle
                             @click="lookDetails">查看详情
                  </el-button>
                </div>
              </div>
            </div>
          </div>
          <div class="title">
            <div class="mes">
              <div class="mes_l">通知与公告</div>
              <div class="mes_r">MORE</div>
            </div>
          </div>
          <div class="notice">
            <div class="notice_item">
              <div class="ni_l">
                <span>
                  <span class="title-style">报名考次</span>
                  &emsp;第{{ applyTimeData.kaoc }}次
                </span>
              </div>
            </div>
            <div v-for="(item, index) in applyTimeData.date_list" :key="'date' + index" class="notice_item">
              <div class="ni_l">
                <span>
                  <span class="title-style">{{ item.date_name }}</span>
                  &emsp;{{ item.date }}
                </span>
              </div>
            </div>
<!--            <div class="notice_item">-->
<!--              <div class="ni_l">-->
<!--                <span>-->
<!--                  <span class="title-style">缴费时间</span>-->
<!--                  &emsp;{{ applyTimeData.payDate }}-->
<!--                </span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="notice_item">-->
<!--              <div class="ni_l">-->
<!--                <span>-->
<!--                  <span class="title-style">信息完善时间</span>-->
<!--                  &emsp;{{ applyTimeData.dataCompleteDate }}-->
<!--                </span>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
        <div class="lc_right">
          <div class="sh_img">
            <img src="../assets/login/loginlogo.png" alt="">
          </div>
          <div class="title"><img src="../assets/login/logo2.png" alt=""></div>
          <div class="login_form">
            <div class="lf_title">已报名学员登录</div>
            <div class="login_input">
              <div class="login_input_item login_input_item_sfz">
                <img src="../assets/login/account.png" alt="">
                <input type="text" v-model="user" maxlength="18" placeholder="请输入身份证">
              </div>
              <div class="login_input_item login_input_item_sfz">
                <img src="../assets/login/password1.png" alt="">
                <input v-model="password" type="password" maxlength="6" placeholder="请输入密码">
              </div>
              <div class="login_input_item">
                <img src="../assets/login/testImg.png" alt="">
                <input v-model="rmInput"
                       placeholder="请输入图形验证码" type="text" maxlength="4">
                <img v-throttle :src="rmImg" class="rmImg" alt="" @click="getRmImg()">
              </div>
            </div>
            <div class="login_button">
                <div class="log_btn" v-throttle @click="login">登录</div>
                <div class="sign_btn" v-throttle @click="signUp">学员报名</div>
              </div>
          </div>
          <div class="pay-area">
            <span class="pay-link" @click="toSecondGradePay">第二年培训费缴纳>></span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-text footer-text-one"><span>请使用谷歌、edge，或其他第三方浏览器（360、搜狗等浏览器）的高速模式访问本系统</span></div>
      <div class="footer-text"><span>西南石油大学继续教育学院</span></div>
    </div>
    <el-dialog
      title="请手机扫码报名"
      center
      :visible.sync="dialogVisible"
      width="60%">
      <div class="imgBox">
        <img src="./trainCostPayment/img/pic20230222182934.png" alt="">
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>
<script>
import webConfig from '@/utils/webSite/webSite.js'
import { reqQueryApplyGuide } from '@/request/login'
import { getNowKaoc, getOperationTime } from '@/request/initData'
import { getRandomImgCode, passwordEncryption } from '@/utils/util'

export default {
  name: 'LoginIndex',
  data () {
    return {
      // 密码是身份证后六位
      // user: '511321199406032587',
      // user: '110106200002140978',
      // password: '032587'
      user: '',
      password: '',
      rmInput: '',
      rmNum: '',
      rmImg: '',
      dialogVisible: false,
      applyTimeData: {
        kaoc: '',
        date_list: []
      },
      show_scale: 1,
      login_page_height: 0,
      login_content_height: 0
    }
  },
  created () {
    this.getOperationData()
    this.getRmImg()
  },
  mounted() {
    const interface_width = document.documentElement.clientWidth
    this.show_scale = interface_width / 1920
    this.login_content_height = this.$refs.login_content.clientHeight
    this.login_page_height = this.$refs.login_page.clientHeight
  },
  methods: {
    open_page (url) { // 打开新窗口
      setTimeout(() => {
        // const formEle = document.createElement('form')
        // formEle.style.display = 'none'
        // formEle.method = 'post'
        // formEle.target = '_blank'
        // formEle.action = url
        // document.body.appendChild(formEle)
        // formEle.submit()
        // formEle.remove()

        const el = document.createElement('a')
        document.body.appendChild(el)
        el.href = encodeURI(url)
        el.target = '_blank'
        el.click()
        document.body.removeChild(el)
      }, 100)
    },
    login () {
      if (!this.user) {
        this.$message.error('请输入身份证！')
        return
      }
      if (!/(^\d{15}$)|(^\d{17}(\d|x|X)$)/.test(this.user)) {
        this.$message.error('请输入正确的身份证！')
        return
      }
      if (!this.password) {
        this.$message.error('请输入密码！')
        return
      }
      if (!this.rmInput) {
        this.$message.error('请输入验证码！')
        return
      }
      this.$store.dispatch('stuLogin', {
        user: this.user.toUpperCase().replace(/\s*/g, ''),
        pwd: passwordEncryption(this.password),
        rmInput: this.rmInput,
        rmNum: this.rmNum
      }).then(_ => {
        this.getRmImg()
      }).catch(_ => {
        this.getRmImg()
      })
    },
    iWantApply () {
      this.$router.push('/main/applyNotice')
    },
    lookDetails () {
      reqQueryApplyGuide({}).then(res => {
        if (res.result.length > 0) {
          if (res.result[0].file_type === 2) {
            const url = webConfig.webSite + res.result[0].file_path
            this.open_page(url)
          } else {
            const url = res.result[0].file_path
            this.open_page(url)
          }
        }else{
          this.$message.error('暂无招生简章')
        }
      })
    },
    signUp () {
      // reqGetPayQRcode({}).then(res => {
      //   this.imgUrl = webConfig.webSite + res.path
      // })
      // this.dialogVisible = true
      this.$store.commit('clearUserApplyData')
      let href = window.location.href.split('#')
      href = href[0] + '#/applyMain'
      window.location.href = href
      // this.$router.push('/applyMain')
    },
    getOperationData () {
      const param = {
        apply_type: 1
      }
      getNowKaoc(param).then(res => {
        this.applyTimeData.kaoc = res.result
      })
      const params = {
        v_branch_id: ''
      }
      getOperationTime(params).then(res => {
        this.applyTimeData.date_list = res.result.filter(v => v.apply_type <= 3).map(v => {
          return {
            date_name: v.type_name,
            date: v.beg_date + ' 至 ' + v.end_date
          }
        })
      })
    },
    getRmImg () {
      this.rmInput = ''
      const obj = getRandomImgCode()
      this.rmNum = obj.rmNum
      this.rmImg = obj.rmImgUrl
    },
    // 前往二年级缴费
    toSecondGradePay () {
      this.$router.push('/secondGradePay/payInfo')
    }
  }
}
</script>
<style scoped lang="less">
.loginPage {
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding-top: 80px;
  overflow-x: auto;
  background: url('../assets/login/loginback.jpg');
  background-repeat: 100% 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .login_content {
    margin: auto;
    width: 1200px;
    height: 700px;
    border-radius: 20px;
    background: #F9FAFC;
    box-sizing: border-box;
    overflow: hidden;

    .cont {
      height: 100%;
      width: 100%;

      display: flex;

      .lc_left {
        background: #F9FAFC;
        width: 600px;
        height: 100%;

        .title {
          padding: 40px 38px 0 40px;
          width: 100%;
          box-sizing: border-box;

          .mes {
            height: 33px;
            box-sizing: border-box;
            width: 100%;
            position: relative;
            border-bottom: 1px solid #CCCCCC;

            .mes_l {
              font-size: 16px;
              width: 80px;
              padding-bottom: 15px;
              border-bottom: 2px solid #157DCB;
              line-height: 1;
              color: #157DCB;
              position: absolute;
              top: 0;
              left: 0;
            }

            .mes_r {
              font-family: "Microsoft YaHei", serif;
              position: absolute;
              right: 0;
              bottom: 17px;
              color: #999999;
              font-size: 12px;
              line-height: 1;
            }
          }
        }

        .notice {
          // padding: ;
          width: 100%;
          padding: 0 37px 0 43px;
          box-sizing: border-box;

          .notice_item {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #F0F0F0;

            .ni_l {
              font-family: "Microsoft YaHei", serif;
              width: 429px;
              color: #333333;
              font-size: 14px;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              //padding: 14px 0 16px;
              padding: 20px 0 20px;
              .title-style{
                display: inline-block;
                width: 112px;
                font-weight: bold;
                color: #000;
                text-align: right;
              }
            }

            .ni_r {
              font-family: "Microsoft YaHei", serif;
              padding-top: 14px;
              box-sizing: border-box;
              color: #999999;
              font-size: 14px;
              display: flex;
              align-items: flex-start;
              height: 32px;
            }
          }
        }

        .sch_introduce {
          padding: 34px 0 0 71px;

          .si_one {
            width: 460px;
            height: 120px;
            position: relative;
            background: #FFD3D4;
            border-radius: 4px;
            display: flex;
            margin-bottom: 27px;

            .si_one_l {
              font-size: 0;
              padding: 15px 24px;

              img {
                width: 150px;
                height: 90px;
              }
            }

            .si_one_r {
              padding-top: 16px;

              .si_one_r_t {
                font-weight: 800;
                color: #D52A2E;
                font-size: 18px;
                line-height: 1;
                margin-bottom: 16px;
              }

              .si_one_r_d {
                color: #ED5A5E;
                font-size: 12px;
                line-height: 1;
              }
            }
          }

          .si_two {
            width: 460px;
            height: 120px;
            background: #AED9FC;
            border-radius: 4px;
            position: relative;
            display: flex;

            .si_two_l {
              font-size: 0;
              padding: 15px 24px;

              img {
                width: 150px;
                height: 90px;
              }
            }

            .si_two_r {
              padding-top: 16px;

              .si_two_r_t {
                font-weight: 800;
                color: #157CCF;
                font-size: 18px;
                line-height: 1;
                margin-bottom: 16px;
              }

              .si_two_r_d {
                color: #419DE8;
                font-size: 12px;
                line-height: 1;
              }
            }
          }
        }
      }

      .lc_right {
        background: #ffffff;
        width: 600px;
        height: 100%;

        .sh_img {
          font-size: 0;
          padding: 30px 0 30px 65px;

          img {
            width: 470px;
            height: 80px;
          }
        }

        .title {
          font-size: 0;
          text-align: center;
          margin-bottom: 50px;

          img {
            width: 437px;
            height: 25px;
          }
        }

        .login_form {
          padding-top: 35px;
          width: 436px;
          height: 375px;
          background: #FFFFFF;
          border-radius: 10px 10px 10px 10px;
          box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
          margin: auto;
          .lf_title {
            font-weight: 800;
            color: #333333;
            font-size: 18px;
            line-height: 1;
            text-align: center;
            width: 100%;
            margin-bottom: 25px;
          }
          .login_input {
            width: 350px;
            margin: auto;
          }
          .login_input {
            margin-bottom: 30px;
            .login_input_item_sfz {
              margin-bottom: 25px !important;
            }
            .login_input_item {
              height: 40px;
              position: relative;
              img:not(.rmImg) {
                width: 20px;
                height: 20px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 10px;
              }
              .rmImg{
                width: 120px;
                height: 40px;
                position: absolute;
                right: 5px;
                top: 50%;
                transform: translateY(-60%);
                cursor: pointer;
              }
              input {
                border: 0;
                width: 100%;
                border-bottom: 1px solid #cccccc;
                height: 40px;
                padding-left: 40px;
                font-size: 16px;
                box-sizing: border-box;
                &:focus {
                  outline: 0 !important;
                }
                &::placeholder {
                  color: #ABABAB;
                  font-size: 15px;

                }
              }
            }
          }
          .login_button {
            .log_btn,
            .sign_btn {
              width: 340px;
              height: 44px;
              background: #157DCB;
              border-radius: 22px;
              line-height: 44px;
              margin: auto;
              text-align: center;
              cursor: pointer;
              font-size: 16px;
              color: white;
            }
            .sign_btn {
              background: #0C814B;
              margin-top: 25px;
            }
          }
        }
        .pay-area{
          width: 436px;
          margin: 20px auto 0;
          text-align: right;
          padding-right: 20px;
          box-sizing: border-box;
          .pay-link{
            cursor: pointer;
            user-select: none;
            color: #56a2ff;
          }
          .pay-link:hover{
            text-decoration: underline;
          }
        }
      }

    }
  }
  .footer {
    text-align: center;
    color: white;
    margin: 70px auto auto;
    padding-bottom: 10px;
    font-size: 14px;
    .footer-text-one {
      margin-bottom: 5px;
    }
  }
  .imgBox{
    margin: 0 auto;
    // margin-top: 100px;
    margin-bottom: 20px;
    width: 320px;
    height: 320px;
    // background-color: pink;
  }
}
@media screen and (max-width: 1366px) {
  .loginPage{
    padding-top: 0;
  }
  .login_content{
    transform-origin: center;
    transform: scale(.85);
    overflow: hidden;
  }
  .footer{
    margin-top: 0 !important;
  }
}
</style>
