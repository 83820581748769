import md5 from 'md5'
import {
  getToken
} from '@/utils/auth'
import { passwordEncryption } from '@/utils/util'

/*
md5加密方式
*/
const SecretKey = 'f34nr32fh843Sddf%$fmidVR4f32_3' // 加密秘钥字符串key
function objKeySort (obj) {
  const newkey = Object.keys(obj).sort()
  const newObj = {}
  let newObjStr = ''
  for (let i = 0; i < newkey.length; i++) {
    newObj[newkey[i]] = obj[newkey[i]]
  }
  Object.keys(newObj).forEach(function (key) {
    if (obj[key] !== undefined) {
      newObjStr += key + '=' + obj[key]
    } else {
      newObjStr += key + '=' + ''
    }
  })
  const yrMd5 = ('swpu' + newObjStr).replace(/[\r\n]/g, '')
  return yrMd5
}

export function encrypt (params, url) {
  let userInfo = getToken()
  if (userInfo == null || userInfo === undefined) {
    userInfo = {}
    userInfo.xs_sfz = ''
    userInfo.token = ''
    userInfo.opreatName = ''
  }

  // let TokenType = getTokenType();
  const TokenType = JSON.parse(sessionStorage.getItem('userInfo'))
  if (TokenType) {
    const token = TokenType.token
    params = {
      token: token,
      v_xs_sfz: TokenType.xs_sfz,
      xs_id: TokenType.xs_id,
      ...params
    }
  } else if (params.needPublicParm) {
    delete params.needPublicParm
  } else {
    params = {
      checkTimeOut: false, // 不需要验证过期登录
      ...params
    }
  }
  params = {
    timestamp: new Date().getTime(),
    nonceStr: md5(new Date().getTime() + '(123)(@#$%&*()x+-_!!)' + Math.floor(Math.random() * 100000)),
    ...params
  }
  // if (!url.includes('stu/login')) {
  if ('v_xs_sfz' in params) {
    params.v_xs_sfz = passwordEncryption(params.v_xs_sfz) // 加密所有的身份证
    // delete  params.v_xs_sfz
  }
  // }
  params.sign = md5(SecretKey + objKeySort(params)).toUpperCase()
  return params
}

export function encryPwd (params) {
  return md5(SecretKey + params).toUpperCase()
}

/*
 *获取当前时间
 */
export function getNowDate () {
  const myDate = new Date()
  const Y = myDate.getFullYear() // 获取完整的年份(4位,1970-????)
  const M = myDate.getMonth() + 1 // 获取当前月份(0-11,0代表1月)
  const D = myDate.getDate() // 获取当前日(1-31)
  const H = myDate.getHours() // 获取当前小时数(0-23)
  const T = myDate.getMinutes() // 获取当前分钟数(0-59)
  const S = myDate.getSeconds() // 获取当前秒数(0-59)
  return Y + '/' + M + '/' + D + ' ' + H + ':' + T + ':' + S
}

/*
 *两个时间相减运算
 */
export function getSecondByDateSub (begin, end) {
  const beginDate = new Date(begin)
  const endDate = new Date(end)
  const diff = endDate.getTime() - beginDate.getTime()
  const sec = diff / 1000
  return sec
}

export function getUserIP (onNewIP) {
  const MyPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection
  const pc = new MyPeerConnection({
    iceServers: []
  })
  const noop = () => {
  }
  const localIPs = {}
  const ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g
  const iterateIP = (ip) => {
    if (!localIPs[ip]) onNewIP(ip)
    localIPs[ip] = true
  }
  pc.createDataChannel('')
  pc.createOffer().then((sdp) => {
    sdp.sdp.split('\n').forEach(function (line) {
      if (line.indexOf('candidate') < 0) return
      line.match(ipRegex).forEach(iterateIP)
    })
    pc.setLocalDescription(sdp, noop, noop)
  }).catch((reason) => {
  })
  pc.onicecandidate = (ice) => {
    if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return
    ice.candidate.candidate.match(ipRegex).forEach(iterateIP)
  }
}

// 阿拉伯数字转换成中文数字
const chnNumChar = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
const chnUnitSection = ['', '万', '亿', '万亿', '亿亿']
const chnUnitChar = ['', '十', '百', '千']

function SectionToChinese (section) {
  let strIns = ''
  let chnStr = ''
  let unitPos = 0
  let zero = true
  while (section > 0) {
    const v = section % 10
    if (v === 0) {
      if (!zero) {
        zero = true
        chnStr = chnNumChar[v] + chnStr
      }
    } else {
      zero = false
      strIns = chnNumChar[v]
      strIns += chnUnitChar[unitPos]
      chnStr = strIns + chnStr
    }
    unitPos++
    section = Math.floor(section / 10)
  }
  return chnStr
}

export function numberToChinese (num) {
  let unitPos = 0
  let strIns = ''
  let chnStr = ''
  let needZero = false

  if (num === 0) {
    return chnNumChar[0]
  }

  while (num > 0) {
    const section = num % 10000
    if (needZero) {
      chnStr = chnNumChar[0] + chnStr
    }
    strIns = SectionToChinese(section)
    strIns += (section !== 0) ? chnUnitSection[unitPos] : chnUnitSection[0]
    // 替换以“一十”开头的，为“十”
    if (strIns.indexOf('一十') === 0) {
      strIns = strIns.substr(1)
    }
    chnStr = strIns + chnStr
    needZero = (section < 1000) && (section > 0)
    num = Math.floor(num / 10000)
    unitPos++
  }

  return chnStr
}

/**
 * 深度克隆
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone (source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 *获取浏览器版本
 */
export function browserVersion () {
  const userAgent = navigator.userAgent // 取得浏览器的userAgent字符串
  const isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 // 判断是否IE<11浏览器
  const isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1
  const isEdge = userAgent.indexOf('Edge') > -1 && !isIE // Edge浏览器
  const isFirefox = userAgent.indexOf('Firefox') > -1 // Firefox浏览器
  const isOpera = userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1 // Opera浏览器
  const isChrome = userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Edge') === -1 && userAgent.indexOf('OPR') === -1 // Chrome浏览器
  const isSafari = userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1 && userAgent.indexOf('Edge') === -1 && userAgent.indexOf('OPR') === -1 // Safari浏览器
  if (isIE) {
    // eslint-disable-next-line prefer-regex-literals
    const reIE = new RegExp('MSIE (\\d+\\.\\d+);')
    reIE.test(userAgent)
    const fIEVersion = parseFloat(RegExp.$1)
    if (fIEVersion === 7) {
      return 'IE:7'
    } else if (fIEVersion === 8) {
      return 'IE:8'
    } else if (fIEVersion === 9) {
      return 'IE:9'
    } else if (fIEVersion === 10) {
      return 'IE:10'
    } else {
      return 'IE:6' // IE版本<7
    }
  } else if (isIE11) {
    return 'IE:11'
  } else if (isEdge) {
    return 'Edge:' + userAgent.split('Edge/')[1].split('.')[0]
  } else if (isFirefox) {
    return 'Firefox:' + userAgent.split('Firefox/')[1].split('.')[0]
  } else if (isOpera) {
    return 'Opera:' + userAgent.split('OPR/')[1].split('.')[0]
  } else if (isChrome) {
    return 'Chrome:' + userAgent.split('Chrome/')[1].split('.')[0]
  } else if (isSafari) {
    return 'Safari:'
  } else {
    return -1 // 不是ie浏览器
  }
}
