export const apply = {
  state: {
    xl_level: sessionStorage.apply_xl_level ? JSON.parse(sessionStorage.apply_xl_level) : '',
    dataComplete: sessionStorage.dataComplete ? JSON.parse(sessionStorage.dataComplete) : {},
    zyId: sessionStorage.zyId ? JSON.parse(sessionStorage.zyId) : '',
    applyDetailData: sessionStorage.applyDetailData ? JSON.parse(sessionStorage.applyDetailData) : ''
  },
  mutations: {
    setXlLevel (state, value) { // 设置学历层次
      state.xl_level = value
      sessionStorage.setItem('apply_xl_level', JSON.stringify(value))
    },
    setDataComplete (state, value) { // 设置完善信息数据
      state.dataComplete = value
      sessionStorage.setItem('dataComplete', JSON.stringify(value))
    },
    setUserZyId (state, zyId) { // 设置专业id
      state.zyId = zyId
      sessionStorage.setItem('zyId', JSON.stringify(zyId))
    },
    setApplyDetailData (state, data) {
      state.applyDetailData = data
      sessionStorage.setItem('applyDetailData', JSON.stringify(data))
    }
  },
  actions: {},
  getters: {}
}
