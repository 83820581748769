import axios from 'axios'
import webConfig from '../webSite/webSite'
import router from '@/router'
import store from '../../store/index.js'
import {
  // MessageBox,
  Message
} from 'element-ui'
import qs from 'qs'
import {
  encrypt
} from '@/utils/api/encryption'
const baseURL = webConfig.webSite
// eslint-disable-next-line no-unused-vars
let isTip = false
// eslint-disable-next-line no-unused-vars
let isFile = false
const url = new Set()
const service = axios.create({
  baseURL,
  // baseURL,
  timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(

  config => {
    isFile = false
    isTip = false
    if (config.isTip) {
      // isTip = true
      url.add(config.url)
    }
    if (config.method === 'post') {
      config.data = {
        platform_flag: '1', // 1是pc端，2是移动端
        ...config.data
      }
      if (!config.isFormData) {
        const res = qs.stringify(encrypt(config.data, config.url))
        config.data = res
      } else {
        const fileData = config.data[config.isFormData]
        delete config.data[config.isFormData]
        const res = encrypt(config.data, config.url)
        res[config.isFormData] = fileData
        const formData = new FormData()
        for (const key in res) {
          if (Object.hasOwnProperty.call(res, key)) {
            formData.append(key, res[key])
          }
        }
        config.data = formData
      }
    }
    // if (config.method === 'post' && config.url === '/selfExamMgr/downLoadFile') { //
    //   isFile = true
    // }
    return config
  },
  error => {
    // console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    // if (isFile && response.status === 200) {
    //   return response.data
    // } else if (isFile) {
    //   Message({
    //     message: res.message || 'Error',
    //     type: 'error',
    //     duration: 5 * 1000
    //   })
    //   return Promise.reject(new Error(res.message || 'Error'))
    // }
    if ('success' in res) {
      if (res.success !== true) {
        if (res.message === 'noLoginMessage') {
          Message({
            message: '登录超时或未登录或异地登录，请重新登录系统！',
            type: 'error',
            duration: 5 * 1000
          })
          sessionStorage.removeItem('userInfo')
          store.commit('removeMenuRoles')
          router.replace({
            path: '/'
            // 登录成功后跳入浏览的当前页面
            // query: {redirect: router.currentRoute.fullPath}
          })
          window.location.reload(true)
          return res
        } else {
          if (!url.has(response.config.url)) {
            Message({
              message: res.message || 'Error',
              type: 'error',
              duration: 5 * 1000
            })
          } else {
            url.delete(response.config.url)
            return Promise.reject(res.message || 'Error')
          }
        }
      } else {
        return res
      }
    } else {
      return res
    }
  },
  error => {
    // 接口错误，刷新页面
    // window.location.reload();
    // console.log('err' + error)
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
