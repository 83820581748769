// 公共方法文件

import webConfig from '@/utils/webSite/webSite'
import md5 from 'md5'
import { JSEncrypt } from 'jsencrypt'
/**
 * 加法运算，避免数据相加小数点后产生多位数和计算精度损失。
 *
 * @param num1加数1 | num2加数2
 */
export function numAdd (num1, num2) {
  let baseNum, baseNum1, baseNum2
  try {
    baseNum1 = num1.toString().split('.')[1].length
  } catch (e) {
    baseNum1 = 0
  }
  try {
    baseNum2 = num2.toString().split('.')[1].length
  } catch (e) {
    baseNum2 = 0
  }
  // eslint-disable-next-line prefer-const
  baseNum = Math.pow(10, Math.max(baseNum1, baseNum2))
  return (num1 * baseNum + num2 * baseNum) / baseNum
}

/**
 * 加法运算，避免数据相减小数点后产生多位数和计算精度损失。
 *
 * @param num1被减数 | num2减数
 */
export function numSub (num1, num2) {
  let baseNum, baseNum1, baseNum2
  let precision // 精度
  try {
    baseNum1 = num1.toString().split('.')[1].length
  } catch (e) {
    baseNum1 = 0
  }
  try {
    baseNum2 = num2.toString().split('.')[1].length
  } catch (e) {
    baseNum2 = 0
  }
  // eslint-disable-next-line prefer-const
  baseNum = Math.pow(10, Math.max(baseNum1, baseNum2))
  // eslint-disable-next-line prefer-const
  precision = (baseNum1 >= baseNum2) ? baseNum1 : baseNum2
  return ((num1 * baseNum - num2 * baseNum) / baseNum).toFixed(precision)
}

/**
 * 乘法运算，避免数据相乘小数点后产生多位数和计算精度损失。
 *
 * @param num1被乘数 | num2乘数
 */
export function numMulti (num1, num2) {
  let baseNum = 0
  try {
    baseNum += num1.toString().split('.')[1].length
  } catch (e) {
  }
  try {
    baseNum += num2.toString().split('.')[1].length
  } catch (e) {
  }
  return Number(num1.toString().replace('.', '')) * Number(num2.toString().replace('.', '')) / Math.pow(10, baseNum)
}

/**
 * 除法运算，避免数据相除小数点后产生多位数和计算精度损失。
 *
 * @param num1被除数 | num2除数
 */
export function numDiv (num1, num2) {
  let baseNum1 = 0
  let baseNum2 = 0
  let baseNum3, baseNum4
  try {
    baseNum1 = num1.toString().split('.')[1].length
  } catch (e) {
    baseNum1 = 0
  }
  try {
    baseNum2 = num2.toString().split('.')[1].length
  } catch (e) {
    baseNum2 = 0
  }

  // eslint-disable-next-line prefer-const
  baseNum3 = Number(num1.toString().replace('.', ''))
  // eslint-disable-next-line prefer-const
  baseNum4 = Number(num2.toString().replace('.', ''))
  return (baseNum3 / baseNum4) * Math.pow(10, baseNum2 - baseNum1)
}

export function parseTime (time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        // eslint-disable-next-line prefer-regex-literals
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  // eslint-disable-next-line camelcase
  return format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
}

export function getUserAgent () { // 检查浏览器版本
  const userAgent = window.navigator.userAgent
  const isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 // 判断是否IE<11浏览器
  const isEdge = userAgent.indexOf('Edge') > -1 && !isIE // 判断是否IE的Edge浏览器
  const isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1
  if (isIE) {
    // eslint-disable-next-line prefer-regex-literals
    const reIE = new RegExp('MSIE (\\d+\\.\\d+);')
    reIE.test(userAgent)
    const fIEVersion = parseFloat(RegExp.$1)
    if (fIEVersion === 7) {
      return 7
    } else if (fIEVersion === 8) {
      return 8
    } else if (fIEVersion === 9) {
      return 9
    } else if (fIEVersion === 10) {
      return 10
    } else {
      return 6 // IE版本<=7
    }
  } else if (isEdge) {
    return 'edge' // edge
  } else if (isIE11) {
    return 11 // IE11
  } else {
    return false // 不是ie浏览器
  }
}

export function getRandomImgCode () {
  const codeObj = {
    rmNum: '',
    rmImgUrl: ''
  }
  codeObj.rmNum = Math.floor(Math.random() * 10000)
  codeObj.rmImgUrl = webConfig.webSite + '/enroll/getImg?rm=' + codeObj.rmNum +
    '&nonceStr=' + md5(new Date().getTime() + '(123)(@#$%&*()x+-_!!)' + Math.floor(Math.random() * 100000))
  return codeObj
}


/**
 * JSEncrypt加密
 * @param {String}  需要加密的参数
 */
export function passwordEncryption (param) {
  // 后台给的公钥
  let publicKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqsq1ZImCN77lsG6fH7W8tXKqj7mMNG16RAElmUJYQ1aBNlBSErc8AG/uZnE0Y2juPDQPKHBalPgud6ioyubKHJlbjWlYxwikDKdQdDMmv/wUfe7awQktHjlVsxEsLfLnIqQoffpCY2PIdCQFzeyo4CbuFPzf6jnz9fC04p3ekb9lp4p6DQXQgvaLg96/Zei9+/C3OpMr6IvYMB6MQBMbOEXOFx28aFkMVMtg6caCpRYdr6QPmV4msIi7GYnn9uobYsfpL1y6ukmBjSr3cygXrpD9MtSa5VTf3mS3eTbpnjIClxnasisYWxnnCrKjUyNynqyFYWWRDp76NW475Aje+QIDAQAB'
  let encryptor = new JSEncrypt() // 新建JSEncrypt对象
  encryptor.setPublicKey(publicKey) // 设置公钥
  let passwordEncryp = encryptor.encrypt(param) // 对密码进行加密
  return passwordEncryp
}

// JSEncrypt解密
export function decryptPwd (msg) {
  const privateKey = 'MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCqyrVkiYI3vuWwbp8ftby1cqqPuYw0bXpEASWZQlhDVoE2UFIStzwAb+5mcTRjaO48NA8ocFqU+C53qKjK5socmVuNaVjHCKQMp1B0Mya//BR97trBCS0eOVWzESwt8ucipCh9+kJjY8h0JAXN7KjgJu4U/N/qOfP18LTind6Rv2WninoNBdCC9ouD3r9l6L378Lc6kyvoi9gwHoxAExs4Rc4XHbxoWQxUy2DpxoKlFh2vpA+ZXiawiLsZief26htix+kvXLq6SYGNKvdzKBeukP0y1JrlVN/eZLd5NumeMgKXGdqyKxhbGecKsqNTI3KerIVhZZEOnvo1bjvkCN75AgMBAAECggEBAJxEtQu0NY1EAW0ZCkc9RdjPfffbUcl2kfQ/CGPeLFwQflFv1GiDd0b/IyZgVgrPK4uyJlxd0MOVAQ9DJo3f9PWN7BLLXQtfgJqDTD3Fv6LRxv4i2czd1IZe92bMQ5wXMBymDtaVcMD3v6VkgFsWOr49YIGbjlUFiY5yG09uGwNGmbvJcXrOvGwophB8nq2htA9v3P6DvtXfawkJYOcBF1/WRQVsNSrNvtSi5cv0vUtMgX40ko/4HLGp3+rUxDIdFu1QwhHCOfIvUDqBe7ftwttOzUOdAx3tVP4241mDNjWkygrCnp5JtXz5Vwea63pPehT7Xl3oe6CCD7xJ8Q9jGcECgYEA1oC6OvIwLal25joK34KPw8KK1y6VZPcSHXmlAJKcGyebC2LUC333S0NyJ1v5/rhhEiERSSfZ4D9vIqI1rzG1gPEYXHgbB0VUXbm34jjnjzIUYKoFCIMzR0zyS1wMGDr4Qeh9GpHKX4s13TYMVTd+YrymhT5W5bDwQUZiAqhOrPUCgYEAy9UweDEedJNtbqMt/jUB+vzgsMSOMNjtJfiPiEtzsbq2P+naVqsVFsRBh1x4TcGqa4AicKEvHBm9mHIvmI88qL6If1V5FStRCZ9KEl6i/JrO63Qe98Gj9zo39hxajJBhmC108DYQaQANe+rgHUejrVjfYBYvrCoRoX6FU8hAp3UCgYEAzSHZ9xk5fdF8hXuofktL1TgsstA3ypCFYG6+nyeV5lof6emfQ0Y5wl5DwuQw1anaGw5Ii59yuwOu8n4RvPNa0m6+j1/E6VUh82fyNIOa3tRTrIWrvpJJ7bvmUq86Wcm7NLxzN3+I+4oT/vL7JFunoCEtVmthitOFT8dRX505cCkCgYBEoF12SgtX8n8InfYRPWWSTGuV+EdrWM3XV+1zVPv53cQYlrKFNYqfJKpvWYnDnC21jemKhCopSt1TozY1JhylL6kjPfLLVs8s7y1wK96yL6j1Cx6WCeMbjMVknj06UC4bn0pcyccSowrnKrgUA5eu+FoUrsOUMoHHHqLjB2wFNQKBgGapF4jaoRz2exg1lR2j2U3h6LuQfZ5Yv8akgOaqhhEQVZ1QMG3ovU+SuUNt1+FnmiZ8dz9lmfejJQNpgavi9fMcUtA4vtBqHhVhmXQMb2ffShxwPb1/C6LzlFf7Ie/8V7vMMfXAZ4KrNT4aezB1KPzTlqtHGu6Wuqh+etD8Y1dw'
  let decrypt = new JSEncrypt()
  decrypt.setPrivateKey(privateKey)
  var decryptMsg = decrypt.decrypt(msg)
  return decryptMsg
}

export function notNum (str) {
  return str.replace(/\d/g, '')
}

export function onlyNum (str) {
  return str.replace(/[^\d]/ig, '')
}
