//测试地址
//const webSite = 'http://120.27.150.141:8024/swpuUnitMgt'  
//const mobileUrl = 'http://120.27.150.141:8025'

//正式地址
const webSite = 'https://zkbm.swpu.028zk01.net/swpuUnitMgt'  
const mobileUrl = 'https://zkbmh5.swpu.028zk01.net'

export default {
  webSite,
  mobileUrl
}
