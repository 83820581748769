import Cookies from 'js-cookie'
// import de from 'element-ui/src/locale/lang/de'

const TokenKey = 'liveCenterKey'

export function getToken () {
  // console.log(Cookies.get(TokenKey))
  // eslint-disable-next-line no-eval
  const data = eval('(' + Cookies.get(TokenKey) + ')')
  // console.log(data)
  try {
    return data ? data.teacher_info[0] : data
  } catch (error) {
    return data
  }
}

export function setToken (token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

export function getTokenType () {
  // console.log(Cookies.get(TokenKey))
  // eslint-disable-next-line no-eval
  return eval('(' + Cookies.get(TokenKey) + ')')
}
