import request from '@/utils/api/requestConfig'

export function dropList (data) { // 获取基本下拉框
  // code_type 代表下面对应数据, 类型是数字
  // 1.学历层次 2.原有学历 3.职业 4.民族 5.政治面貌 6.身体状况 7.户籍 8.学生状态 9.学习资料类型 10.一级机构 11.二级机构 12.课程类型 13.就读形式
  return request({
    url: '/base/droplist',
    method: 'post',
    data
  })
}

export function getSsq (data) { // 获取省市区， 获取省不需要传code_id
  return request({
    url: '/base/listSsq',
    method: 'post',
    data
  })
}

export function getEnrolList (data) { // 获取专业列表
  return request({
    url: '/base/queryValidZyList',
    method: 'post',
    data
  })
}

export function getBranchList (data) { // 获取助学点下拉列表
  return request({
    url: '/recruit/queryBranchList',
    method: 'post',
    data
  })
}

export function getNowKaoc (data) { // 获取当前考次, 1报名时间 2缴费时间 3考籍资料完善时间
  return request({
    url: '/base/getApplyKaocBm',
    method: 'post',
    data
  })
}

export function getOperationTime (data) { // 获取操作限制时间范围
  return request({
    url: '/recruit/queryApplyDateList',
    method: 'post',
    data
  })
}

export function dropLimitsQuery (data) { // 验证是否开通退费
  return request({
    url: '/enroll/checkStuApply',
    method: 'post',
    data
  })
}
