import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginIndex from '@/views/LoginIndex'
import { Message } from 'element-ui'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: LoginIndex,
    meta: {
      title: '登录',
      head: false
    }
  },
  {
    path: '/secondGradePay',
    name: 'secondGradePay',
    redirect: '',
    component: () => import('@/views/secondGradePay/index'),
    children: [
      {
        path: 'payInfo',
        name: 'payInfo',
        component: () => import('@/views/secondGradePay/payInfo'),
        meta: {
          title: '二学年缴费',
          head: true,
          isApply: true
        }
      }
    ]
  },
  {
    path: '/main',
    name: 'main',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/MainPage'),
    redirect: '/main/dashed',
    children: [
      {
        path: 'dashed',
        name: 'dashed',
        meta: {
          title: '首页',
          head: true,
          showNav: true
        },
        // component: () => import(/* webpackChunkName: "about" */ '@/views/homePage')
        component: () => import(/* webpackChunkName: "about" */ '@/views/NewHomePage')
      },
      {
        path: 'applyDataQueryAndAmend',
        name: 'applyDataQueryAndAmend',
        meta: {
          title: '报名查询和修改',
          head: true,
          showNav: true
        },
        // component: () => import('@/views/applyData/ApplyDataQueryAndAmend')
        component: () => import('@/views/applyData/NewApplyDataQueryAndAmend')
      },
      {
        path: 'applyNotice',
        name: 'applyNotice',
        meta: {
          title: '报名须知',
          head: false,
          showNav: false
        },
        component: () => import('@/views/stuApply/ApplyNotice')
      },
      {
        path: 'stuApply',
        name: 'styApply',
        meta: {
          title: '学生报名',
          head: true,
          showNav: true
        },
        component: () => import('@/views/stuApply/ApplyPage')
      },
      {
        path: 'onlinePay',
        name: 'onlinePay',
        meta: {
          title: '在线缴费',
          head: true,
          showNav: true
        },
        component: () => import('@/views/trainCostPayment/OnlinePay')
      },
      {
        path: 'payQuery',
        name: 'payQuery',
        meta: {
          title: '缴费查询',
          head: true,
          showNav: true
        },
        component: () => import('@/views/trainCostPayment/PayQuery.vue')
      },
      {
        path: 'dataComplete',
        name: 'dataComplete',
        meta: {
          title: '数据完善',
          head: true,
          showNav: true
        },
        component: () => import('@/views/dataComplete/DataComplete.vue')
      },
      {
        path: 'confirmTable',
        name: 'confirmTable',
        meta: {
          title: '信息确认表',
          head: true,
          showNav: true
        },
        component: () => import('@/views/dataComplete/confirmTable.vue')
      },
      {
        path: 'auditRate',
        name: 'auditRate',
        meta: {
          title: '审核进度',
          head: true,
          showNav: true
        },
        component: () => import('@/views/dataComplete/AuditRate.vue')
      },
      {
        path: 'identityCard',
        name: 'identityCard',
        meta: {
          title: '准考证查询',
          head: true,
          showNav: true
        },
        component: () => import('@/views/identityCard/index.vue')
      },
      {
        path: 'dropOut',
        name: 'dropOut',
        component: () => import('@/views/stuDropOut/DropOutList'),
        meta: {
          title: '退学申请',
          head: true,
          showNav: true
        }
      },
      {
        path: 'dropOutQuery',
        name: 'dropOutQuery',
        component: () => import('@/views/stuDropOut/DropOutQuery'),
        meta: {
          title: '申请查询',
          head: true,
          showNav: true
        }
      },
      {
        path: 'notifiLook',
        name: 'notifiLook',
        component: () => import('@/views/notifiLook/notifiLook'),
        meta: {
          title: '通知书查看',
          head: true,
          showNav: true
        }
      }
    ]
  },
  {
    path: '/applyMain',
    name: 'applyMain',
    component: () => import('@/views/beforeLoginApply/ApplyIndex'),
    redirect: '/applyMain/applyReadme',
    meta: {
      head: true,
      isApply: true // 用于判断是报名页面，不用路由守卫验证
    },
    children: [
      {
        path: 'applyReadme',
        name: 'applyReadme',
        component: () => import('@/views/beforeLoginApply/components/ApplyReadme'),
        meta: {
          title: '报名须知',
          head: true,
          isApply: true
        }
      },
      {
        path: 'selectBranch',
        name: 'selectBranch',
        component: () => import('@/views/beforeLoginApply/components/SelectBranch'),
        meta: {
          title: '选择助学点',
          head: true,
          isApply: true
        }
      },
      {
        path: 'selfData',
        name: 'selfData',
        // component: () => import('@/views/beforeLoginApply/components/SelfData'),
        component: () => import('@/views/beforeLoginApply/components/NewSelfData'),
        meta: {
          title: '注册学员资料',
          head: true,
          isApply: true
        }
      },
      {
        path: 'selectClass',
        name: 'selectClass',
        component: () => import('@/views/beforeLoginApply/components/SelectClass'),
        meta: {
          title: '选择报名专业',
          head: true,
          isApply: true
        }
      },
      {
        path: 'applySuccess',
        name: 'applySuccess',
        component: () => import('@/views/beforeLoginApply/components/ApplySuccess'),
        meta: {
          title: '报名成功',
          head: true,
          isApply: true
        }
      }
    ]
  },
  {
    path: '/takePicture',
    name: 'takePicture',
    component: () => import('@/views/TakePhotoPage'),
    meta: {
      title: '拍照小程序',
      head: false,
      isApply: true
    }
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/ErrorPage.vue')
  },
  {
    path: '/appSeeNotifi',
    name: 'appSeeNotifi',
    component: () => import('@/views/AppSeeNotifi.vue'),
    meta: {
      title: '查看通知书',
      head: false
    }
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
  if (to.path === '/' || to.path === '/AppSeeNotifi') {
    next()
  } else {
    if (to.meta.isApply) { // 如果是报名页面就可以进入
      next()
    } else if (!userInfo) {
      // 如果访问非登录界面，且户会话信息不存在，代表未登录，则跳转到登录界面
      Message.error('请先登录！')
      next('/')
    } else {
      next()
    }
  }
})

export default router
