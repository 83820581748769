import request from '@/utils/api/requestConfig'

export function login (data) {
  return request({
    url: '/stu/login',
    method: 'post',
    data
  })
}

// 调管理端查询招生简章
export function reqQueryApplyGuide (data) {
  return request({
    url: '/recruit/queryApplyGuide',
    method: 'post',
    data
  })
}
// 登录后报名进度查询
export function getEnrollProcess (data) {
  return request({
    url: '/enroll/getEnrollProcess',
    method: 'post',
    data
  })
}
