export const beforeLoginApply = {
  state: {
    beforeLoginApplyData: sessionStorage.beforeLoginApplyData ? JSON.parse(sessionStorage.beforeLoginApplyData) : {},
    apply_select_branch: sessionStorage.apply_select_branch ? JSON.parse(sessionStorage.apply_select_branch) : '',
    apply_select_branch_name: sessionStorage.apply_select_branch_name ? JSON.parse(sessionStorage.apply_select_branch_name) : '',
    applyZyId: sessionStorage.applyZyId ? JSON.parse(sessionStorage.applyZyId) : '',
    selectClassIndex: sessionStorage.selectClassIndex ? JSON.parse(sessionStorage.selectClassIndex) : null,
    selectClassName: sessionStorage.selectClassName ? JSON.parse(sessionStorage.selectClassName) : '',
    applyProgress: sessionStorage.applyProgress ? JSON.parse(sessionStorage.applyProgress) : 0,
  },
  mutations: {
    setApplyData (state, data) { // 保存预报名参数
      state.beforeLoginApplyData = data
      sessionStorage.setItem('beforeLoginApplyData', JSON.stringify(data))
    },
    setApplyBranch (state, branch) { // 设置助学点id
      state.apply_select_branch = branch
      sessionStorage.setItem('apply_select_branch', JSON.stringify(branch))
    },
    setApplyBranchName (state, branchName) { // 设置助学点名称
      state.apply_select_branch_name = branchName
      sessionStorage.setItem('apply_select_branch_name', JSON.stringify(branchName))
    },
    setApplySelectClassIndex (state, zyId) { // 保存报名选择的课程id
      state.applyZyId = zyId
      sessionStorage.setItem('applyZyId', JSON.stringify(zyId))
    },
    setApplySelectClassName (state, className) { // 保存报名选择的课程名称
      state.selectClassName = className
      sessionStorage.setItem('selectClassName', JSON.stringify(className))
    },
    setSelectClassIndex (state, index) { // 保存报名选择的课程列表索引
      state.selectClassIndex = index
      sessionStorage.setItem('selectClassIndex', JSON.stringify(index))
    },
    changeBranchClearClass (state) { // 切换助学点清除已经选择的课程
      state.selectClassIndex = null
      sessionStorage.removeItem('selectClassIndex')
      state.selectClassName = ''
      sessionStorage.removeItem('selectClassName')
    },
    clearUserApplyData (state) { // 清除数据
      state.beforeLoginApplyData = {}
      state.apply_select_branch = ''
      state.applyZyId = ''
      state.selectClassIndex = ''
      sessionStorage.removeItem('beforeLoginApplyData')
      sessionStorage.removeItem('apply_select_branch')
      sessionStorage.removeItem('applyZyId')
      sessionStorage.removeItem('selectClassIndex')
    },
    setApplyProgress (state, index) { // 设置报名进度，如果进度不对，就直接返回登录页
      state.applyProgress = index
      sessionStorage.setItem('applyProgress', JSON.stringify(index))
    },
    removeApplyProgress (state) { // 清除报名进度
      state.applyProgress = null
      sessionStorage.removeItem('applyProgress')
    }
  },
  actions: {},
  getters: {}
}
